<template>
  <TableLayout :permissions="['huntbus:huntbus:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="序号" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入序号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="大区" prop="area">
          <el-input v-model="searchForm.area" placeholder="请输入大区" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="服务器" prop="server">
          <el-input v-model="searchForm.server" placeholder="请输入服务器" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="版本" prop="version">
          <el-input v-model="searchForm.version" placeholder="请输入版本" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="地图" prop="map">
          <el-input v-model="searchForm.map" placeholder="请输入地图" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="路线" prop="road">
          <el-input v-model="searchForm.road" placeholder="请输入路线" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开车时间" prop="time">
          <el-input v-model="searchForm.time" placeholder="请输入开车时间" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input v-model="searchForm.note" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['huntbus:huntbus:create', 'huntbus:huntbus:delete']">
        <li><el-button type="primary" @click="$refs.operaHuntbusWindow.open('新建狩猎车表')" icon="el-icon-plus" v-permissions="['huntbus:huntbus:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['huntbus:huntbus:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="序号" min-width="100px"></el-table-column>
        <el-table-column prop="area" label="大区" min-width="100px"></el-table-column>
        <el-table-column prop="server" label="服务器" min-width="100px"></el-table-column>
        <el-table-column prop="version" label="版本" min-width="100px"></el-table-column>
        <el-table-column prop="map" label="地图" min-width="100px"></el-table-column>
        <el-table-column prop="road" label="路线" min-width="100px"></el-table-column>
        <el-table-column prop="time" label="开车时间" min-width="100px"></el-table-column>
        <el-table-column prop="note" label="备注" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['huntbus:huntbus:update', 'huntbus:huntbus:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaHuntbusWindow.open('编辑狩猎车表', row)" icon="el-icon-edit" v-permissions="['huntbus:huntbus:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['huntbus:huntbus:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaHuntbusWindow ref="operaHuntbusWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaHuntbusWindow from '@/components/OperaHuntbusWindow'
export default {
  name: 'Huntbus',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaHuntbusWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        area: '',
        server: '',
        version: '',
        map: '',
        road: '',
        time: '',
        note: ''
      }
    }
  },
  created () {
    this.config({
      module: '狩猎车表',
      api: '/huntbus',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
