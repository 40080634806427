<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="大区" prop="area" required>
        <el-input v-model="form.area" placeholder="请输入大区" v-trim/>
      </el-form-item>
      <el-form-item label="服务器" prop="server" required>
        <el-input v-model="form.server" placeholder="请输入服务器" v-trim/>
      </el-form-item>
      <el-form-item label="版本" prop="version" required>
        <el-input v-model="form.version" placeholder="请输入版本" v-trim/>
      </el-form-item>
      <el-form-item label="地图" prop="map" required>
        <el-input v-model="form.map" placeholder="请输入地图" v-trim/>
      </el-form-item>
      <el-form-item label="路线" prop="road" required>
        <el-input v-model="form.road" placeholder="请输入路线" v-trim/>
      </el-form-item>
      <el-form-item label="开车时间" prop="time" required>
        <el-input v-model="form.time" placeholder="请输入开车时间" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input v-model="form.note" placeholder="请输入备注" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaHuntbusWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        area: '',
        server: '',
        version: '',
        map: '',
        road: '',
        time: '',
        note: ''
      },
      // 验证规则
      rules: {
        area: [
          { required: true, message: '请输入大区' }
        ],
        server: [
          { required: true, message: '请输入服务器' }
        ],
        version: [
          { required: true, message: '请输入版本' }
        ],
        map: [
          { required: true, message: '请输入地图' }
        ],
        road: [
          { required: true, message: '请输入路线' }
        ],
        time: [
          { required: true, message: '请输入开车时间' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/huntbus',
      'field.id': 'id'
    })
  }
}
</script>
