<template>
  <div class="home">
    <div class="wrap">
      <h2>欢迎使用FF14CN狩猎站后台管理系统</h2>
      <p>您使用的此套开源框架没有任何版权问题，可学习可商用，请放心使用！</p>
      <p>↑上面这条是给你们看的↑，我怕拖了这么久着急用，所以把我抄的这个开源项目的默认前端也抄过来了</p>
      <p>后续的处理问题就交给我们的草莓大佬吧❤代码扔在服务器上了，随时随地都可以看</p>
      <p>swagger接口文档地址：<a>https://101.43.32.88:10010/doc.html</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.home {
  text-align: center;
  color: #777;
  .wrap {
    margin-top: 80px;
    img {
      width: 240px;
    }
  }
  h2 {
    font-size: 32px;
    color: #555;
    margin-bottom: 20px;
  }
  p {
    line-height: 24px;
    margin: 0;
  }
  .start-up {
    margin-top: 8px;
  }
  .guide {
    margin: 30px 0 40px 0;
    display: flex;
    justify-content: center;
    a {
      margin-right: 12px;
      padding: 12px 40px;
      border-radius: 30px;
      background: $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
  em,a {
    font-style: normal;
    font-weight: bold;
    margin: 0 3px;
    color: $primary-color;
  }
  .award {
    position: absolute;
    right: 20px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 12px;
    border: 1px solid #eee;
    box-shadow: -1px 1px 10px #ccc;
    h4 {
      font-weight: bold;
      margin-bottom: 8px;
    }
    img {
      width: 160px;
      margin-bottom: 20px;
      transition: opacity ease .3s;
    }
  }
}
</style>
